.count_css{
    width: 40%;
}
.main_css{
    display: flex;
    flex-direction: column;
}
.card_shadow{

  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
}