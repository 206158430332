button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
.css-1q2h7u5.Mui-selected {
    /* color: #FFA700 !important; */
    color: #454CC7 !important;
    font-family: 'Poppins', sans-serif !important;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: 'Poppins', sans-serif !important;

}

.MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer,
.MuiTabs-flexContainer.css-k008qs {
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin: auto;
}

button:focus {
    outline: none;
}

h3.h3_model_status {
    margin: 25px 0;
    font-size: 20px;
}

.model_status_container {
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 0 30px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

img.status_icon {
    width: 40px;
}

.button_space {
    margin-bottom: 30px;
    margin-right: 30px;
}

.marquee_div {
    display: flex;
    background-color: #f46262;
    border-left: 5px solid seagreen;
    height: 40px;
    color: aliceblue;
    align-items: center;
}

button.upload_contract {
    background: #6759b1;
    color: #fff;
    border: 1px solid #fff;
    width: 120px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0;
    height: inherit;
}

.header_profile {
    color: #454CC7;
    display: flex;
    margin: 30px 60px;
    text-transform: capitalize;
}

.container {
    width: 100%;
    border: 1px solid #ced4da;
    height: 70px;
    padding-left: 25px;
    font-size: 18px;
    border-radius: 5px;
    background-color: #f4f4f4;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button.button_submit1 {
    border: 1px solid #4635A2;
    margin-left: 10px;
    background: #dad2ff;
    padding: 4px 10px;
    font-size: 15px;
}

.button_remove {
    border: 1px solid #a23535;
    margin-left: 10px;
    background: #ffd2d2;
    padding: 4px 10px;
    font-size: 15px;
}

.button_remove:hover {
    color: #fff;
    background: #a23535;
    border: none;
}

.image_name {
    contain: content;
    width: 85%;
    height: 30px;
    text-align: initial;
}

.modal_image {
    width: 50vw;
    height: 80vh;
    padding: 20px;
}

img.status_icon1 {
    width: 30px;
    margin-right: 25px;

}