.card_total_css{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    /* margin: 10px; */
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
}
.total_sow_css{
    margin-left: 20px;
    margin-right: 20px;
}
.sow_logo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.row_space_evnly{
    display: contents;
}
.border_bg{
    padding: 5px;
    background-color: #F5F8F8;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
}
.head_base_css{
    font-size: 10px;
    color: #9B9B9B;
}
.about_text_css{
    text-align: left;
    margin-left: 16px;
    color: #0C0D5B;
    font-weight: 400;
    font-size: 14px;
}
.desc_tect_css{
    font-size: 12px;
    text-align: justify;
    padding-right: 20px;
    padding-left: 16px;
    color: #333333;
    margin-top: 10px;
    contain: content;
    height: 100px;
    overflow: scroll;
    text-transform: lowercase;
}
.view_css{
    text-align: right;
    margin-right: 18px;
    font-size: 11px;
    text-decoration: underline;
    margin-bottom: 21px;
    color: #BE6FA6;
    margin-top: 10px;
    cursor: pointer;
}
.sow_head_css{
    color: #6759b1;
    font-size: 16px;
    font-weight: 600;
}
.head_sow_css{
    justify-content: space-between;
    margin-right: 35px;
}
.sow_css_right{
    background-color: #595BC7;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    color: white;
    display: flex;
    align-items: center;
    height: 30px;
    margin: auto 0;
}
.sow_card_scroll{
    overflow: scroll;
    flex-wrap: nowrap !important;
}
.sow_card_scroll::-webkit-scrollbar {
    display: none;
  }
