.total_add_sow_sp{
    width: 700px;
    display: flex;
    flex-direction: column;
    margin: 30px 40px;
}
.total_fse_search{
    width: 700px;
    display: flex;
    flex-direction: column;
    margin: 30px 40px;
}
.heading_addspsow{
    display: flex;
    justify-content: space-between;
}
.heading_addsp{
    color: #3844A3;
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
}
.user_card_total {
    display: flex;
    flex-direction: column;
    width: 250px;
    box-shadow: 0px 0px 7px #ededed;
    border-radius: 5px;
    border: #E7E7E7 1px solid;
    align-items: center;
    padding-bottom: 25px;
}
.status {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
}
.text_bg {
    background-color: #EBFDE7;
    padding: 5px 10px;
    border-radius: 0px 0px 0px 10px;
    color: #509F3D;
    font-weight: 600;
    font-size: 12px;
}
/* .total_grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
} */
.total_grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.profile_img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}
.user_name {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
    text-transform: capitalize;
}
.position_outline {
    margin-top: 25px;
}
.pos_text {
    padding: 5px 15px;
    border: 1px solid #EDEDED;
    border-radius: 5px;
    color: #6759B1;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}
.loc_css {
    color: #FF8900;
    font-size: 12px;
    margin-top: 15px;
    font-weight: 600;
}
.line {
    border-bottom: 1px solid #E7E7E7;
    margin: 15px 20px 0px 20px;
}
.view_outline {
    margin-top: 25px;
}
.view_text {
    padding: 5px 18px;
    border: 1px solid #4535A1;
    border-radius: 20px;
    color: #2B3CBE;
    font-weight: 600;
    font-size: 10px;
    cursor: pointer;
}
.view_text1 {
    padding: 5px 18px;
    border: 1px solid #509F3C;
    border-radius: 20px;
    color: #509F3C;
    font-weight: 600;
    font-size: 10px;
    cursor: pointer;
}
.heading_fse_search{
    color: black;
    font-weight: 600;
}
.desc_fse_css{
    color: gray;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 600;
}