h2.header {
    color: #454CC7;
    font-size: 18px;
    margin: 10px;
}

img.button_add {
    width: 20px;
    height: 20px;
    margin-left: -6px;
}

.div_right {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
}

button.tabls_box {
    border: 1px solid #d4d4d4;
    width: 110px;
    font-weight: 400;
    height: 40px;
    background: #fff;
    color: #2B3CBE;
    cursor: pointer;
}

button.active {
    background: #ffd800;
    color: #000;
    border: none;
}

img.profile_edit {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

img.edit_icon {
    width: 45px;
    height: 45px;
    cursor: pointer;
}

input#ControlInput {
    height: 70px;
    padding-left: 25px;
    font-size: 18px;
}

.form-control:focus {
    box-shadow: none;
}

button.button_submit {
    float: right;
    width: 130px;
    height: 40px;
    background: #dad2ff;
    border: 1px solid #4635A2;
    color: #4635A2;
    margin-right: 60px;
}

button.onboard_button {
    float: right;
    width: 130px;
    height: 40px;
    background: #d2ffdf;
    border: 1px solid #09823e;
    color: #036812;
    margin-right: 60px;
}

.button_submit:hover,
.button_submit1:hover {
    color: #fff !important;
    background: #6759b1;
    border: none;
}

button.false_button {
    float: right;
    width: 130px;
    height: 40px;
    background: #bababa !important;
    color: #fff !important;
    cursor: no-drop !important;
    border: none !important;
    pointer-events: none !important;
}

img.right_wrong {
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-left: 20px;

}

.buttons_container {
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: space-around;
}

/* button.button_blue {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 8px;
    width: 130px;
    min-height: 45px;
    justify-content: space-evenly;
    color: #fff;
    text-transform: uppercase;
    background: #6C57FF;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 25%);
    border-radius: 8px;
    font-size: 18px;
} */

/* img.button_add {
    width: 18px;
    height: 18px;
    margin-left: -6px;
} */

.modal-dialog,
.modal-content {
    width: 80vw;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.add_page {
    width: 75vw;
    padding: 0 60px 40px 60px;
    overflow: scroll !important;
    overflow: hidden;
}
.add_page1 {
    width: 40vw;
    padding: 0 60px 40px 60px;
    overflow: scroll !important;
    overflow: hidden;
}
.add_page2{
    width: 40vw;
    padding: 0 60px 40px 60px;
    overflow: scroll !important;
    overflow: hidden;
}

.font_change {
    font-size: 30px;
    color: #404D61;
    margin: 0;

}

h1.h1_header {
    margin: 0;
    display: flex;
    margin-left: 15px;
    margin-bottom: -38px;
    font-size: 32px;
    color: #3844a3;
    text-transform: uppercase;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f4f4f4;
    opacity: 1;
    font-size: 18px;

}

.form-control::placeholder {
    color: #000;
    opacity: 1;
    font-size: 18px;
    /* Firefox */
}

button.button_blue {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 8px;
    width: 130px;
    min-height: 45px;
    justify-content: space-evenly;
    color: #6C57FF;
    text-transform: uppercase;
    background: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
    border-radius: 8px;
    border: 1px solid #6C57FF;
    font-size: 18px;
}

button.button_blue1 {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 8px;
    width: 130px;
    min-height: 37px;
    justify-content: space-evenly;
    color: #6C57FF;
    text-transform: uppercase;
    background: #fff;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
    border-radius: 8px;
    border: 1px solid #6C57FF;
    font-size: 18px;
}

#back-to-top {
    position: fixed;
    bottom: 75px;
    right: 8px;
    width: 40px;
    height: 40px;
    z-index: 1000;
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background-color: #cbc0ff;
    align-items: center;
    border: none;
    display: none;
    /* border: 1px solid #4635A2; */


}

#back-to-top:hover {
    bottom: 60px;
}

img.logo_projects {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

h3.model_sow2 {
    color: #6759B1;
    font-size: 20px;
    text-transform: capitalize;
}

h3.model_sow3 {
    color: #FF8124;
    font-size: 20px;
}

h6.model_sow1 {
    color: #6D6D6D;
    text-transform: capitalize;
}

.model_row_sow.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
}

.model_sow_div {
    text-align: initial;
    margin-top: 50px;
}

.switch_leads {
    display: flex;
    justify-content: end;
    margin-bottom: -13px;
    margin-right: 20px;
}