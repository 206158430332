.spend_text {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 700;
  font-size: 24px;
}
.chart_css{
    margin-left: 50px;
    margin-right: 50px;
}
.chart_css .apexcharts-legend {
    text-align: start !important;
}
tspan {
  font-size: 14px !important
}