.add_heading {
    display: flex;
    margin-top: 35px;
    font-size: 18px;
    color: #1019B1;
    font-weight: 600;
    text-transform: uppercase;
}

.drop_css {
    height: 70px;
    padding-left: 25px;
    font-size: 18px;
}