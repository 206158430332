.model_contain3 {
    text-align: center;
    background: #fff;
    padding: 40px;
    margin: 0 45px;
    border-radius: 8px;
}

img.otp_gif {
    width: 200px;
    margin: 15px;
}

label.label_add_sow {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
}

select.select_download {
    height: 35px;
    padding-left: 15px;
    font-size: 13px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
}

input.input_add_otp {
    float: left;
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    padding: 4px 6px;
    width: 100%;
}


.otpButton {
    margin-top: 60px;
    cursor: pointer;
    width: 100px;
    background: #9d81ff 0% 0% no-repeat padding-box;
    color: #fff;
    padding: 4px 8px;
    border-radius: 5px;
}

img.blast_gif {
    width: 300px;
}

p.otp_VERIFY {
    margin-top: -178px;
    font-size: 35px;
}
.hideDataFirstInput,.hideDataSecondInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.hideDataSecondInput{
    margin-top: 20px;
}

.sendButton{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.hideDataFirstInputStyle{
    width: 200px;
}
.headermodalgender{
    width: 700px;
    height: 1300px;
    display: flex;
    flex-direction: column;
    margin: 30px 40px;
    padding: 10px !important;
}
.modal-content{
    padding: 19px
}
.gendermodalbutton{
    position: absolute;
    bottom: 10px;
    right: 10px;
}