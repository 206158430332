button.print {
  display: none;
}

.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.jzGaAN.kSJejC.crSOFH.rdt_TableCol {
  background-color: #ececec !important;
  color: black;
  font-size: 14px;
  font-weight: 400;
}

.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.ducYGK,
.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.kwUxXG,
.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.jzGaAN.dDvEzq.crSOFH.rdt_TableCol {
  background: #ececec;
}

.data-table-extensions-filter {
  float: right;
  padding-right: 25px;
}

img.profile_table_images {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 10px 0px 0px 0px;
}

/* .onboarded {
    background: #18AB56;
    height: 40px;
} */

img.view_image {
  width: 28px;
  height: 30px;
  margin: auto;
}

img.offer_letter {
  width: 30px;
  height: 30px;
  margin: auto;
}

.sc-bczRLJ.hFNXNY.rdt_Table,
.sc-fLlhyt.gcPjVa {
  margin-top: 30px;
}

input.filter-text {
  height: 35px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid;
  font-size: 14px;
  width: 230px;
}

.status_ball {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: auto;
  margin-right: 5px;
  margin-left: -12px;
}

.onboarded,
.active {
  background: #00bc50;
}

.all,
.enable {
  background: #6759b1;
}

.closed {
  background: #64370d;
}

.inactive,
.disable {
  background: #ff3459;
}

.none,
.new {
  background: #ffbb00;
}

.both {
  background: rgb(159, 104, 0);
}

.filter_box {
  display: flex;
  float: right;
  height: 35px;
  cursor: pointer;
  border: 1px solid;
  width: 110px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 2px 2px 7px 0px lightgrey;
  margin-right: 2rem;
}

.add_box {
  display: flex;
  float: right;
  height: 35px;
  cursor: pointer;
  width: 110px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 2px 2px 7px 0px lightgrey;
  margin-right: 2rem;
}

p.doj {
  margin: 0;
  color: #9f9f9f;
  font-size: 10px;
  text-align: left;
}

p.name {
  margin: 0;
  width: 100px;
  text-align: left;
}

p.date_name {
  margin: 0;
}

.name_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
}

p.filter_name {
  margin: 0;
  margin-left: 10px;
}

.filter_options {
  position: absolute;
  right: 0;
  margin-right: 3rem;
  background: #fff;
  width: 110px;
  z-index: 5;
  margin-top: 35px;
  border: 1px solid #b5b5b57d;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

ul.ul_filter {
  padding: 0;
  margin: 3px 0;
  list-style-type: none;
  font-size: 14px;
}

li.li_filter {
  text-align: initial;
  padding: 5px 15px;
  cursor: pointer;
  display: flex;
  padding-left: 20px;
}

.li_filter:hover {
  background-color: #ececec;
  font-size: 16px;
  /* padding-left: 15px; */
  font-weight: 600;
}

.status_ball1 {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: auto 0;
  margin-right: 5px;
  margin-left: -12px;
}

.sub_margin {
  margin-right: 4px;
}

.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.jYPiPR.cdcITA.qVZpv.rdt_TableCol,
.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.jzGaAN.dsRtQm.crSOFH.rdt_TableCol,
.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.jzGaAN.dyboPq.crSOFH.rdt_TableCol,
.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.jYPiPR.jOKAuA.qVZpv.rdt_TableCol,
.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.jYPiPR.nyGnM.qVZpv.rdt_TableCol,
.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.jYPiPR.mBIeA.qVZpv.rdt_TableCol,
.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.jYPiPR.dXwgng.qVZpv.rdt_TableCol {
  background: #ececec !important;
}

.float_right {
  float: right;
}

.gendermodalmain {
  height: 300px;
}

.headerModalgender {
  height: 200px;
}

.sow-list {
  display: flex;
  align-items: center;
  gap: 12px;
}

.sow-btn {
  display: flex;
  justify-content: flex-end;
}

.gender-select {
  margin-left: 20px;
  /* margin-bottom:2px; */
}

::-webkit-scrollbar {
  width: 1px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  } */

hr.w-70 {
  width: 70%;
  margin: auto;
  margin-top: 18px;
}