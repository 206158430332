.login {
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginText {
  font-weight: 600;
  font-size: 40px;
  text-align: initial;
  margin-bottom: 33px;
  margin-top: 10px;
  color: #000;
  text-transform: uppercase;
  margin-left: 40px;
}

.login_button {
  background: white;
  border: #000 1px solid;
  display: flex;
  width: 75%;
  height: 45px;
  box-sizing: border-box;
  box-shadow: 2px 4px 17px -5px rgb(0 0 0 / 20%);
  border-radius: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
}

img.login_img {
  width: 60px;
  height: 40px;
}

p.login_p {
  margin: auto;
  font-size: 14px;
}

p.login_p {
  margin: auto 0;
}