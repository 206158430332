.modal_crop {
    min-width: 90vw;
    background: #fff;
    border-radius: 10px;
    min-height: 700px;
    padding: 20px 40px;
    margin: auto auto;
    margin-left: -120px;

}

.model_contain_crop {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex {
    display: flex;
}

.margin_top {
    margin-top: 10px;
}