.spend_text {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 700;
  font-size: 24px;
}

.chart_css11 {
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  padding-top: 10px;
  overflow: scroll;
  min-height: 321px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.chart_css1 {
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  padding-top: 10px;
  overflow: scroll;
  overflow: hidden;
}

.chart_css .apexcharts-legend {
  text-align: start !important;
}

.chart_css2 {
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  padding-top: 10px;
}

.loader {
  align-items: center;
}

.loader_gif {
  width: 300px;
  height: auto;
}