.sidebar {
  background: #FBF9FF;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 180px;
  justify-content: space-between;
  position: fixed;
}

.side_row1 {
  margin-top: 15px;
  margin-bottom: 20px;
}

.side_option {
  width: 24px;
  margin-right: 12px;
}

.paper_icon {
  width: 20px;
  margin-right: 12px;
}

.head_text {
  color: #f0b94b;
  font-size: 20px;
}

.logo {
  /* width: 42px; */
  height: 42px;
  padding-top: 15px;
}

.text_taskmo {
  font-size: 15px;
  margin: 0px;
  color: whitesmoke;
}

.text_finance {
  font-size: 30px;
  margin: 0px;
  color: whitesmoke;
  font-weight: 800;
}

.side_tabs {
  cursor: pointer;
  padding: 12px;
  margin: 4px 0px;
  font-weight: 700;
  font-size: 15px;
  color: whitesmoke;
  height: 50px;
}

.side_text {
  font-size: 13px;
  font-weight: 400;
}

.side_tabs1 {
  cursor: pointer;
  background: #E2DEFF;
  padding: 12px;
  margin: 4px 0px;
  font-weight: 700;
  font-size: 15px;
  color: #121e2e;
  height: 50px;
  border-radius: 8px;
}

img.side_arrow {
  width: 8px;
  height: 15px;
  display: flex;
  margin: auto 0;
}

/* .logout_div {
  position: sticky;
  bottom: 20px;
} */