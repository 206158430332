.sp_profile_total{
    background-image: url("../../../public/images/png/asmbg.png");
    background-repeat: no-repeat;
    background-size: contain;
    /* min-height: 250px; */
    /* width: 100% !important; */
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-size: cover;


}
.card_right_css{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 10px;
    height: 100px;
    width: 20%;
    margin: 11.35% 2% 0% 3%;
    justify-content: center;
}
.amt_css{
letter-spacing: 0px;
color: #999EBF;
font-size: 24px;
font-weight: 600;
}
.amt_sub_css{
 letter-spacing: 0px;
color: #999EBF;
font-size: 15px;
}
.asm_img {
	border-radius: 50%;
	height: 100px;
	width: 100px;
}
.img_total{
    margin-top: 6%;
    margin-left: 6%;
    height: 115px;
    width: 115px;
    border-radius: 50%;
    border: #25235C 1px solid;
    background-color: #D5E2F3;
    display: grid;
    justify-content: center;
    align-items: center;
}
.details_css{

    display: flex;
    flex-direction: column;
    margin-top: 23%;
    margin-left: 5%;
    margin-bottom: 3%;
    text-align: start;

}
.icon_bg_css{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    background-color: #FDEFFF;
    justify-content: center;
    align-items: center;
    color: #7840C3;
}
.details_css_head{
    color: #fff;
    float: left;
    font-size: 13px;
}