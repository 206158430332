.img_css_upload {
    width: 100%;
    height: 321px;
    border: 1px #D8D8D8 solid;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.head_css_img {
    display: flex;
    margin: 25px;
    color: #6C757D;
    font-size: 14px;
}

.certificate_css {
    width: 350px;
    height: 220px;
}

label.form-label,
label.label_image {
    float: left;
    font-size: 14px;
    margin-left: 25px;
    margin-top: 10px;
    text-transform: capitalize;
    color: #6C757D;
    font-family: 'Poppins', sans-serif !important;
}