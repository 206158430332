.nav_head {
    background: #FBF9FF;
    min-height: 80px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.margin-0 {
    margin: 0;
}



.threeline {
    padding-left: 10px;
}

.icons_end {
    background: #240605 0% 0% no-repeat padding-box;
    height: 37px;
    width: 37px;
    border-radius: 5px;
    opacity: 1;
    align-items: center;
    margin-top: -6px;
    padding-top: 5px;
    padding-left: 1px;
    margin-right: 20px;
}

/* .profile {
    background: #240605 0% 0% no-repeat padding-box;
    height: 35px;
    width: 35px;
    border-radius: 50px;
    opacity: 1;
    align-items: center;
    margin-top: -6px;
    padding-top: 5px;
    padding-left: 1px;
    margin-right: 20px;
    margin-left: 5px;
    text-transform: uppercase;
    color: aliceblue;
} */

.profile {
    background: #240605 0% 0% no-repeat padding-box;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    align-items: center;
    display: flex;
    margin-left: 5px;
    text-transform: uppercase;
    color: aliceblue;
    align-content: center;
    justify-content: center;
}
.profile_1 {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    align-items: center;
    display: flex;
    margin-left: 5px;
    text-transform: uppercase;
    color: aliceblue;
    align-content: center;
    justify-content: center;
    border: #240605 1px solid;
}

.nav_profile {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

p.nav_p1 {
    font-weight: 700;
    font-size: 14px;
    text-align: left;
}

p.nav_p2 {
    color: #100DB1;
    font-size: 12px;
}
.logout_css{
    border-top: 0.5px solid black;
    padding-top: 5px;
    margin-top: 10px;
    display: flex;
}
.css_text_menu{
    margin-left: 10px;
}